export default {
  labelHours: 'Часы',
  labelMinutes: 'Минуты',
  labelSeconds: 'Секунды',
  labelIncrement: 'Увеличить',
  labelDecrement: 'Уменьшить',
  labelSelected: 'Выбранное время',
  labelNoTimeSelected: 'Время не выбрано',
  labelCloseButton: 'Закрыть',
}
