
import { BvEvent } from 'bootstrap-vue'
import { Component, Vue } from 'vue-property-decorator'
import { shopsStore } from '@/store/shopsStore'
import { IShop } from '@/interfaces/IShop'
import timePickerLocalization from '@/l10n/timePicker'
const { mask } = require('vue-the-mask')

@Component({
  directives: {
    mask,
  },
})
export default class ShopEditModal extends Vue {
  state: 'edit' | 'create' = 'create'
  pending = false

  id: string | null = null
  title = ''
  phone = ''
  address = ''
  openingTime = ''
  closingTime = ''
  startOrdersTime = ''
  endOrdersTime = ''
  telegramNotifications = ''
  deliveryPrice = 0
  deliveryThreeshold = 0
  isDeliveryEnabled = true
  deliveryDisabledMessage = ''

  errors: { [key: string]: string | null } = {
    title: null,
    phone: null,
    address: null,
    time: null,
    timeOrders: null,
    deliveryPrice: null,
    deliveryThreeshold: null,
  }

  get timePickerLocalization() {
    return timePickerLocalization
  }

  get modalTitle() {
    return this.state === 'create' ? 'Создать магазин' : 'Редактировать магазин'
  }

  get okTitle() {
    return this.state === 'create' ? 'Создать' : 'Сохранить'
  }

  show(shop: IShop) {
    if (shop) {
      this.id = shop.id
      this.title = shop.title
      this.phone = shop.phone
      this.address = shop.address
      this.deliveryPrice = shop.deliveryPrice
      this.deliveryThreeshold = shop.deliveryThreeshold
      this.telegramNotifications = shop.telegramNotifications || ''
      this.openingTime = `${shop.openingTime}:00`
      this.closingTime = `${shop.closingTime}:00`
      this.startOrdersTime = `${shop.startOrdersTime}:00`
      this.endOrdersTime = `${shop.endOrdersTime}:00`
      this.isDeliveryEnabled = shop.isDeliveryEnabled
      this.deliveryDisabledMessage = shop.deliveryDisabledMessage
      this.state = 'edit'
    } else {
      this.state = 'create'
    }
    this.$bvModal.show('shop-edit-modal')
  }

  async handleOk(e: BvEvent) {
    e.preventDefault()
    if (!this.validate()) {
      return
    }

    this.pending = true
    const payload = this.getPayload()

    if (this.state === 'create') {
      if (!(await shopsStore.createShop(payload))) {
        this.pending = false
        return
      }
    } else {
      if (!(await shopsStore.updateShop(payload))) {
        this.pending = false
        return
      }
    }

    this.$bvModal.hide('shop-edit-modal')
  }

  getPayload(): IShop {
    return {
      id: this.id,
      title: this.title,
      phone: this.phone,
      address: this.address,
      openingTime: this.openingTime.slice(0, 5),
      closingTime: this.closingTime.slice(0, 5),
      startOrdersTime: this.startOrdersTime.slice(0, 5),
      endOrdersTime: this.endOrdersTime.slice(0, 5),
      telegramNotifications: this.telegramNotifications,
      deliveryPrice: this.deliveryPrice,
      deliveryThreeshold: this.deliveryThreeshold,
      isDeliveryEnabled: this.isDeliveryEnabled,
      deliveryDisabledMessage: this.deliveryDisabledMessage,
    }
  }

  validate() {
    this.resetErrors()

    if (!this.title) {
      this.errors.title = 'Введите название'
    }
    if (this.title.length < 2) {
      this.errors.title = 'Название слишком короткое'
    }
    if (this.title.length > 30) {
      this.errors.title = 'Название слишком длинное'
    }

    if (!this.phone || this.phone.length !== 18) {
      this.errors.phone = 'Введите телефон'
    }

    if (!this.address) {
      this.errors.address = 'Введите адрес'
    }

    if (typeof this.deliveryPrice === 'string' || this.deliveryPrice < 0) {
      this.errors.deliveryPrice = 'Стоимость доставки должна быть больше 0'
    }

    if (
      typeof this.deliveryThreeshold === 'string' ||
      this.deliveryThreeshold < 0
    ) {
      this.errors.deliveryThreeshold = 'Стоимость должна быть больше 0'
    }

    if (!this.openingTime || !this.closingTime) {
      this.errors.time = 'Выберите время'
    } else {
      const formatTime = (time: string) => {
        const split = time.split(':')
        return parseInt(split[0]) * 60 + parseInt(split[1])
      }
      if (formatTime(this.openingTime) >= formatTime(this.closingTime)) {
        this.errors.time = 'Время открытия позже времени закрытия'
      }
    }

    if (!this.startOrdersTime || !this.endOrdersTime) {
      this.errors.timeOrders = 'Выберите время'
    } else {
      const formatTime = (time: string) => {
        const split = time.split(':')
        return parseInt(split[0]) * 60 + parseInt(split[1])
      }
      if (formatTime(this.startOrdersTime) >= formatTime(this.endOrdersTime)) {
        this.errors.timeOrders =
          'Время начала принятия заказов позже времени окончания'
      }
    }

    for (const key in this.errors) {
      const error = this.errors[key]
      if (error) {
        return false
      }
    }

    return true
  }

  resetErrors() {
    for (const key in this.errors) {
      this.errors[key] = null
    }
  }

  reset() {
    this.resetErrors()

    this.pending = false

    this.id = null
    this.title = ''
    this.phone = ''
    this.address = ''
    this.telegramNotifications = ''
    this.deliveryPrice = 0
    this.deliveryThreeshold = 0
    this.startOrdersTime = ''
    this.endOrdersTime = ''
    this.openingTime = ''
    this.closingTime = ''
  }
}
