
import { Component, Vue, Ref } from 'vue-property-decorator'
import ShopEditModal from '@/components/modals/ShopEdit.vue'
import { shopsStore } from '@/store/shopsStore'
import { IShop } from '@/interfaces/IShop'
import DeleteConfirmationModal from '@/components/modals/DeleteConfirmation.vue'

@Component({
  components: { ShopEditModal, DeleteConfirmationModal },
})
export default class Shops extends Vue {
  @Ref() readonly deleteConfirmationModal!: {
    show: (title: string) => Promise<boolean>
  }
  @Ref() readonly shopEditModal!: { show: (shop?: IShop) => void }

  pending = false
  fields = [
    { key: 'enabled', label: '' },
    {
      key: 'title',
      label: 'Название',
      sortable: true,
      tdClass: 'title',
    },
    {
      key: 'buttons',
      label: '',
      sortable: false,
    },
  ]

  get shops() {
    return shopsStore.shops
  }

  addShopModalOpen() {
    this.shopEditModal.show()
  }

  editShop(shop: IShop) {
    this.shopEditModal.show(shop)
  }

  async onEnableChanged(shop: IShop, value: boolean) {
    this.pending = true
    await shopsStore.changeShopEnabled({ id: shop.id!, value })
    this.pending = false
  }

  async deleteShop(shop: IShop) {
    const result = await this.deleteConfirmationModal.show(shop.title)
    if (result) {
      shopsStore.deleteShop(shop.id!)
    }
  }
}
